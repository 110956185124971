exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-about-us-tsx": () => import("./../../../src/templates/AboutUs/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-tsx" */),
  "component---src-templates-connect-with-us-connect-with-us-tsx": () => import("./../../../src/templates/ConnectWithUs/ConnectWithUs.tsx" /* webpackChunkName: "component---src-templates-connect-with-us-connect-with-us-tsx" */),
  "component---src-templates-main-main-tsx": () => import("./../../../src/templates/Main/Main.tsx" /* webpackChunkName: "component---src-templates-main-main-tsx" */),
  "component---src-templates-news-main-news-main-tsx": () => import("./../../../src/templates/NewsMain/NewsMain.tsx" /* webpackChunkName: "component---src-templates-news-main-news-main-tsx" */),
  "component---src-templates-press-details-press-details-tsx": () => import("./../../../src/templates/PressDetails/PressDetails.tsx" /* webpackChunkName: "component---src-templates-press-details-press-details-tsx" */),
  "component---src-templates-privacy-policy-privacy-policy-tsx": () => import("./../../../src/templates/PrivacyPolicy/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-tsx" */),
  "component---src-templates-products-products-tsx": () => import("./../../../src/templates/Products/Products.tsx" /* webpackChunkName: "component---src-templates-products-products-tsx" */),
  "component---src-templates-services-services-tsx": () => import("./../../../src/templates/Services/Services.tsx" /* webpackChunkName: "component---src-templates-services-services-tsx" */)
}

